

.ressource  {
    width: 100%;
   min-height: 250px;
   max-height: 250px;
}

.card-bod {
   min-height: 150px;
   max-height: 150px;
   padding: 10px;
}

.card-title {
 font-weight: 500;
 font-size: 16px;
}

.padding {
   padding: 50px;
}

@media only screen and (max-width: 768px) {
   .serv {
     padding-left: 1px;
     padding-right: 1px; 
     padding-bottom: 20px;
     padding-top: 20px;
   }


   
.ressource  {
  min-height: 250px;
  max-height: 250px;

}

.card {
   width: 50%;
}

.card-bod {
  min-height: 150px;
  max-height: 150px;
  padding: 10px;
}

.card-title {
font-weight: 500;
font-size: 16px;
}
 
   .card  img{
      
     
    }
 
 }

 
@media only screen and (max-width: 425px) {
  
   
.ressource  {
 width: 100%;
 height: 100%;

}

.card {
   width: 100%;
}

.card-bod {
  min-height: 150px;
  max-height: 150px;
  padding: 10px;
}

.card-title {
font-weight: 500;
font-size: 16px;
}
 
   
      
     
    
 
 }

  
@media only screen and (max-width: 375px) {
  
   
.padding {
   padding: 30px;
}
   
   .ressource  {
    width: 100%;
    height: 10vh;
   
   }
   
   .card {
      width: 100%;
   }
   
   .card-bod {
     min-height: 150px;
     max-height: 150px;
     padding: 10px;
   }
   
   .card-title {
   font-weight: 500;
   font-size: 14px;
   }
    
      
    
    }

@media only screen and (max-width:320px) {
   .padding {
      padding: 20px;
   }
  
 }
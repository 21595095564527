.revenue__box, .orders__box, .products__box, .users__box {
    padding: 20px;
    border-radius: 5px;
}

.revenue__box {
    background: var(--card-bg-01);
}

.products__box {
    background: var(--card-bg-03);
    width: 80%;
}

.orders__box {
    background: var(--card-bg-04);
}

.users__box {
    background: var(--card-bg-04);
    width: 80%;
}

.revenue__box h5, 
.orders__box h5, 
.products__box h5, 
.users__box h5{
    font-size: 1.1rem;
    font-weight: 500;
    color: var(--primary-color);
    margin-bottom: 20px;
}

.revenue__box span, 
.orders__box span,
.products__box span,
.users__box span  {
    color:  var(--primary-color);
    font-weight: 600;
    font-size: 2rem;
}

.dash {
    display: flex;
    width: 100%;
    text-align: center;
}

.search{
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
   
}

.search__box {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
    padding-right: 12px;
    padding-left: 2px;
    cursor: pointer;

}

.search__box input {
    width: 60%;
    border: none;
    outline: none;
    padding: 8px 10px;
}

.search__box span {
    color: var(--primary-color);
}

@media only screen and (max-width: 425px) {
    
.search__box {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
    padding-right: 12px;
    padding-left: 2px;
    cursor: pointer;

}
}


@media only screen and (max-width: 375px) {
    
    .search__box {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid var(--primary-color);
        border-radius: 5px;
        padding-right: 12px;
        padding-left: 2px;
        cursor: pointer;
    
    }
    }
.fixed-header {
    margin-bottom: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;

    /* Couleur de fond de la barre fixe, ajustez selon vos besoins */
    /* Autres styles que vous souhaitez appliquer à la barre fixe */
}

.contain {
    display: flex;
    justify-content: space-between;
    gap: 200px;

}

.active {
    font-weight: 700;
    font-size: 1rem;

}


.inactive {
    font-weight: 500;
    font-size: 1rem;
}

.navbar {
    height: 60px;
}


.menu {
    display: flex;
    column-gap: 2.5rem;
    margin-bottom: 0;



}

.nav-links {
    padding: 10px;
}

.pan {
    margin-left: 15px;
}

.nav__item {
    margin-right: 10px;
}

.nav__icons {
    display: flex;
    align-items: center;
    column-gap: 1.8rem;

}

.nav__icons span i {
    font-size: 1.4rem;
    color: var(--primary-color);
    cursor: pointer;
}

.navbar {
    background-color: #fff;
}

.mobile__menu {
    font-size: 1.3rem;
    color: var(--primary-color);
    display: none;
}

.contener {
    background-color: var(--primary-color);
}

.contact {

    padding: 10px;
}

.email {
    padding: 10px;

}

.head {


    color: #fffdfd;
}

.group {
    padding-top: 5px;


}

.text {
    padding: 5px;

}


.logo {
    display: flex;
    align-items: center;
    margin-right: 220px;



}



.logo img {
    width: 50px;
    height: 50px;
}

.logo h1 {
    font-size: 1.2rem;
    font-weight: 700;
    color: var(--primary-color);

}

/* header.css */


.navbar .dropdown-menu {
    border-top: 4px solid red;
    /* Bordure rouge en haut du dropdown */
    width: 300px;
    padding: 20px;
}

.navbar .dropdown-menu li {
    padding: 10px;
}

.dropdown span {
    font-weight: 500;
    font-size: 1rem;
}



.btn_Link:hover {
    background-color: red;
    padding: 10px;
    color: white;
}


@media only screen and (max-width:1024px) {


    .logo {

        margin-right: 100px;

    }

    .navigation p {
        font-size: 14px;

    }

    .nav__wrapper a {
        font-size: 14px;
        padding: 5px;

    }


}



@media only screen and (max-width:992px) {


    .nav__item a {
        font-size: 12px;
    }

    .dropdown-menu a {
        font-size: 12px;
    }
}

@media only screen and (max-width:768px) {

    .dropdown-menu a {
        font-size: 10px;
    }

    
    .navbar .dropdown-menu {
        width: 270px;
        padding: 0;
    }

    .nav__wrapper a {
        font-size: 14px;
        padding: 5px;

    }


    .navigation {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgb(0, 0, 0, 0, 53);
        z-index: 9999;
        display: none;
    }

    .menu {
        position: absolute;
        top: 0px;
        right: 0;
        width: 400px;
        height: 100%;
        background: #fff;
        z-index: 99999;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }

    .menu a {
        padding: 30px;
        text-align: center;
        font-size: 600;
        font-size: 20px;
    }

    

    .dropdown span {
        font-weight: 500;
        font-size: 20px;
    }

    .active__menu {
        display: block;
    }

    .mobile__menu {
        display: block;
    }

    .mobile__menu span i {
        font-size: 1.1rem !important;
    }

    .mobile__menu {
        display: flex;
    }


    .nav__wrapper {

        margin-bottom: 10px;

    }




}

@media only screen and (max-width:425px) {

    .menu {
        position: absolute;
        top: 0px;
        right: 0;
        width: 300px;
        height: 100%;
        background: #fff;
        z-index: 99999;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }

    .navbar .dropdown-menu {
        width: 220px;
        padding: 0;
    }

   

    .logo {

        margin-right: 10px;

    }


    .logo h1 {
        font-size: 14px;
        font-weight: 700;
        color: var(--primary-color);

    }

    .menu a {
        padding: 30px;
        text-align: center;
        font-size: 600;
        font-size: 16px;
    }


    .dropdown span {
        font-weight: 500;
        font-size: 16px;
    }


}


@media only screen and (max-width:375px) {
    
    .menu {
        position: absolute;
        top: 0px;
        right: 0;
        width: 250px;
        height: 100%;
        background: #fff;
        z-index: 99999;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }

    .navbar .dropdown-menu {
        width: 200px;
        padding: 0;
    }

    .dropdown-menu a {
        font-size: 12px;
    }

    
.logo img {
    width: 30px;
    height: 30px;
}

.logo h1 {
    font-size: 14px;
    font-weight: 700;
    color: var(--primary-color);

}

.head span{
 font-size: 12px;
}

.head svg {
    width: 16px;
    height: 16px;
}

}

@media only screen and (max-width:360px) {
    
.logo h1 {
    font-size: 12px;
    font-weight: 700;
    color: var(--primary-color);

}
}


@media only screen and (max-width:320px) {


    .search {
        padding-top: 14px;
        margin-right: 10px;
    }

    .nova {
        margin-right: 5px;
        margin-left: 10px;
    }

    .nova img {
        display: none;
    }


    .input {

        height: 30px;
        padding: 0px 10px;
        background-color: #FFF;
        color: #150422;
        font-size: 10px;
        outline: none;

    }

    .btn {
        font-size: 10px;
    }

    .nav__wrapper {

        margin-bottom: 10px;

    }


    .input-group {
        width: 200px;
        display: flex;

    }



    .publicite {
        font-size: 11px;
    }




}
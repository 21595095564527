.admin__header {
    width: 100%;
    padding: 20px 0px;
    background: var(--primary-color);
}

.admin___nav-top {
    width: 100%; 
}


.admin__nav-warpper-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 3rem;
}

.admin___nav-top-right {
    display: flex;
    align-items: center;
    column-gap: 2rem;
}

.admin___nav-top .search__box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.admin___nav-top-right span i{
    color: whitesmoke;
    cursor: pointer;
}

.admin__nav-warpper-top .logo h2 {
    font-size: 1.2rem;
    color: whitesmoke;
}

.admin__menu {
    width: 100%;
    height: 70px;
    line-height: 70px;
    background: var(--card-bg-02);
}

.admin__navigation {
    text-align: center;
}

.admin__menu-list {
    display: flex;
    align-items: center;
    column-gap: 2.7rem;
    justify-content: center;
}

.admin__menu-list .admin__menu-item a{
    color: var(--primary-color);
    font-weight: 500;
    padding: 10px 15px;
    border: 5px;    
}

.active__admin-menu {
    background: whitesmoke;
}

.Navlink p{
    
    font-size: 18px;
}

@media only screen and (max-width: 576px) {
    .Navlink p{
        color: white;
        font-size: 16px;
    }

    .admin__nav-warpper-top .logo h2 {
        font-size: 1rem;
        color: whitesmoke;
    }
}
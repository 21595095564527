 

 .auth__form {
    padding: 40px;
    border-radius: 5px;
 }

 .auth__btn {
    background: #fff !important;
    color: var(--primary-color);
    font-weight: 600;
 }

 .auth__form p {
    margin-top: 30px;
 }

 .auth__form p a {
    color: rgba(255, 255, 255, 0.733);
 }

 .auth__form input[type="file"] {
   color: #fff;
   cursor: pointer;
 }


 /****************Contact ******************/

 .contact__prod {
  padding: 20px;
 }


 .contacte {
  border : 1px solid #efefef;
  border-radius:5px;
  background-color: #efefef ;
  padding: 10px;
 }

 .contact input{
   width: 80%;
 }

 .contnous h2{
  font-size: 30px;
  font-weight: 600;
 }

 .contact button {
   width: 60%;
 }

 .contact textarea {
   width: 80%;
 }

 .contactus  li{
  font-size: 25px;
  font-weight: 600;
  color: var(--primary-color);
}

 
.footer__contact  p{
  font-size: 25px;
  font-weight: 600;
  color: var(--primary-color);
}


.footer__contact  span{
  font-size: 25px;
  font-weight: 600;
  color: var(--primary-color);
}

.contenu {
  gap: 10px;
}

.envoyer {
  justify-content: center;
  text-align: center;
}

@media only screen and (max-width: 992px) {
  
.footer__contact  span{
  font-size: 20px;
  font-weight: 600;
  color: black;
}

 
.footer__contact  p{
  font-size: 20px;
  font-weight: 600;
  color: black;
}

}


@media only screen and (max-width: 768px) {
  
  .footer__contact  span{
    font-size: 22px;
    font-weight: 600;
    color: black;
  }
  
   
  .footer__contact  p{
    font-size: 22px;
    font-weight: 600;
    color: black;
  }
  
  }

  
@media only screen and (max-width: 576px) {
  
  .footer__contact  span{
    font-size: 14px;
    font-weight: 600;
    color: black;
  }
  
   
  .footer__contact  p{
    font-size: 14px;
    font-weight: 600;
    color: black;
  }
  
  .contnous h2{
    font-size: 20px;
    font-weight: 600;
    margin-top: 20px;
   }

  }


/*******************Service ***************/

.service  img{
  width: 100%;
 min-height: 250px;
 max-height: 250px;
 border-radius: 10px;
}

.serv {
  padding-left: 100px;
  padding-right: 50px; 
}

.service {
  padding: 20px;
}

.service_prod {
  margin-top: 20px;
}

.der {
  margin-bottom: 50px;
}

@media only screen and (max-width: 992px) {
  .serv {
    padding-left: 1px;
    padding-right: 1px; 
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .service  img{
    width: 100%;
   min-height: 200px;
   max-height: 200px;
   border-radius: 10px;
   padding: 20px;
  }

}


@media only screen and (max-width: 768px) {
  .serv {
    padding-left: 1px;
    padding-right: 1px; 
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .service  img{
    
   
   border-radius: 10px;
   padding: 20px;
   
  }

}


@media only screen and (max-width: 576px) {
  .serv {
    padding-left: 1px;
    padding-right: 1px; 
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .service  img{
    width: 80%;
   border-radius: 10px;
   padding: 20px;
   
  }

}

/***********************************signup***********************/

.signup input {
  width: 300px;
}

 

.auth__form {
  background: var(--primary-color);
  padding: 40px;
  border-radius: 5px;
}

.auth__btn {
  background: #fff !important;
  color: var(--primary-color);
  font-weight: 600;
}


.buy__b {
 border: none;
 outline: none;
 padding: 8px 20px;
 border-radius: 5px; 
 color:  var(--primary-color);;
 cursor: pointer;
 font-size: 1rem;




}


.buy__b A:hover {
 color: #fff;
}

.auth__form p {
  margin-top: 30px;
}

.auth__form p a {
  color: rgba(255, 255, 255, 0.733);
}

.form__group input{
  
  width: 400px;
  padding: 8px 20px;
 border-radius: 5px; 
 cursor: pointer;
 font-size: 1rem;
}

/********************contact****************/

.contact-wrapper{
  background: linear-gradient(#1b2e4b8f,rgba(54, 51, 51, 0.562)),
 url('/src/assets/images/contact.jpg') no-repeat center center;
  background-size: cover;
  width: 100%;
  height: 40vh;
  justify-content: center;
  background-position: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}


.card-bodye {
  width: 100%;
  min-height: 180px;
  max-height: 180px;
}


.card-title {
  font-weight: 600;
  font-size: 18px;
}


.btn {
  border-radius: 10px;
  font-size: 18px;
  border: 1px solid var(--primary-color);
  transition: 0.5s ease;
}

.btn:hover {
  background-color: var(--primary-color);
  color: white;
  border: 1px solid white;
}

.service_p h1 {
  font-size: 1.5rem;
  font-weight: 600;

}


.ser {
  padding-left: 70px;

}

.navlink {
  background-color: rgb(220, 235, 248);
  margin-top: 20px;
  padding-bottom: 30px;


}

.navlink li {
  padding-top: 30px;
  /* Adjust margin as needed */
  font-size: 20px;
  padding-bottom: 20px;
}


.navlink a.active {
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;

}

.navlink a.inactive {
  color: grey;
  /* Inactive link color */
}

.call {
  background-color: rgb(83, 9, 9);
  color: white;
  margin-top: 20px;
  padding-bottom: 20px;
  justify-content: center;
  text-align: center;
}

.callnow {
  font-size: 22px;
}

.call li {
  padding: 20px;
  font-size: 20px;
  font-weight: 600;
}

.entete {
  text-align: center;
}

.entete span {
  font-size: 20px;
  font-weight: 600;
}


@media only screen and (max-width:768px) {

  .service_p {
    margin-bottom: 80px;
  }



  .navlink li {
    padding-top: 20px;
    /* Adjust margin as needed */
    font-size: 10px;
    padding-bottom: 10px;
  }

}

@media only screen and (max-width:425px) {

  .ser {
    padding-left: 20px;
  
  }
}

@media only screen and (max-width:375px) {

  
  .service_p h1 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 10px;

  }

  .ser {
    padding-left: 20px;
  
  }
}

@media only screen and (max-width:320px) {

  .card-bodye {
    width: 100%;
  max-height: 230px;
  } 
}
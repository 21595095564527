
.hero__section{    background: linear-gradient(#1b2e4b8f,rgba(0, 0, 0, 0.562)),url('../assets/images/banner/call2.webp') no-repeat center center;
    background-size: cover;
    padding: 100px 0px;
    display: flex;
    align-items: center;
    height: 500px;

}

.discute {
   padding-top: 40px;
   
    
}


.cont h6{
  font-size: 2.5rem;
  font-weight: 600;
  color: white;
}


.cont {
    padding: 10px;
}



  .offre h2 {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 30px;
  }



.butt {
    margin-top: 50px;
}


.next {
  margin-top: 420px;
  position: fixed;
  z-index: 10000;
  padding: 20px;

}

.button-link {
    border-radius: 10px;
    font-size: 18px;
    padding: 10px 8px;
    background-color: var(--primary-color);
    color: white;
    border: 1px solid transparent;
    transition: 0.5s ease;
}

.button-link:hover {
    background: transparent;
  color: var(--primary-color);
  border: 1px solid white;
}



.next {
  margin-top: 420px;
  position: fixed;
  z-index: 10000;
  padding: 20px;

}

.butt-link {
    border-radius: 10px;
    font-size: 20px;
    padding: 10px 8px;
    background-color: red;
    color: white;
    border: 1px solid transparent;
    transition: 0.5s ease;
    font-weight: 600;
}

.butt-link:hover {
    background: transparent;
  border: 1px solid white;
  font-weight: 600;
}

.hero__content {
    padding-top: 45px;
}





@media only screen and (max-width: 992px) {
    .hero__content h2 {
        font-size: 2rem;
    }

    .section.title{
        font-size: 1.7rem;
    }

    .counter__img{
        display: none;
    }

    .clock__top__content {
        text-align: center;
    }

    .clock__top__content h4 {
        font-size: 0.9rem !important;
    }

    .clock__top__content h3{
        font-size: 1rem !important;
    }

    .count__down-col {
        text-align: center;
       
    }

     
  .pagination a{
    font-size: 14px;
    padding: 8px;
    color: var(--primary-color);
    
  }
   
.videosrc video{
    width: 80%;
    height: 70%;
    border-radius: 10px;
  }
}


@media only screen and (max-width: 768px) {
 
  .hero__section {
    padding: 50px 0;
    height: auto;
    background-position: center center;
  }

  .offre h2 {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 30px;
  }

   
.next {
  margin-top: 220px;
  position: fixed;
  z-index: 10000;
  padding: 20px;

}

    .card {
    margin-bottom: 20px;
    }

    
}

@media only screen and (max-width: 425px) {

  .offre h2 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .hero__section {
    padding:  0;
    background-position: center center;

  }


  
.next {
  margin-top: 168px;
  position: fixed;
  z-index: 10000;
  padding: 20px;

}
    .hero__content {
        padding-top: 0;
        margin-bottom: 40px;

    }

    .imghome img{
        padding: 20px;
    }

    .cont h6 {
        font-size: 16px;
        font-weight: 600;
       
    }

    .cont span {
      font-size: 12px;
    }
     
.butt-link {
  border-radius: 4px;
  font-size: 12px;
  padding: 6px 4px;
  background-color: red;
  color: white;
  border: 1px solid transparent;
  transition: 0.5s ease;
  font-weight: 600;
}

.butt-link:hover {
  background: transparent;
border: 1px solid white;
font-weight: 600;
}
 


  .offre h2 {
    font-size: 25px;
    font-weight: 700;
  }

}

@media only screen and (max-width: 375px){ 
 .offre h2 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
  }
}


/******************ABOUT ***************/

.about-wrapper{
    background: url(/src/assets/images/banner2.jpg);
    background-size: cover;
    width: 100%;
    height: 40vh;
    justify-content: center;
    background-position: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  
  .card-body span {
    font-weight: 500;
    font-size: 14px;
  }
  
  
.card-title {
    font-weight: 600;
    font-size: 16px;
  }

  .about {
    width: 50%;
    text-align: center;
  }
  

